import React from 'react';
import { Row, Col, Container} from 'react-bootstrap'
import './Page.css';
import uscca from './uscca.jpg';
import usccacommunity from './newusccacommunity.jpg';
import usccahalfsize from './usccahalfsize.jpg';
import deltadefenseonefouthsize from './deltadefenseonefouthsize.jpg';
import usccafourthsize from './usccafourthsize.jpg';
import deltadefenseline from './deltadefenseline.jpg';
export const Page1 = ()  => (

<div class="container-fluid  p-3 my-3 bg-black">
<img src={usccacommunity} class="responsive" alt="usccacommunity" />   

</div>

    )

    export default Page1;

