import Container from 'react-bootstrap/Container';
import { Row, Col} from 'react-bootstrap'
import Navbar from 'react-bootstrap/Navbar';
import './Newheader.css';
import deltadefenseonefouthsize from './deltadefenseonefouthsize.jpg';
import usccafourthsize from './usccafourthsize.jpg';
import { BrowserView } from 'react-device-detect';


function BrandExample() {
  return (
    <>
  <BrowserView>
      <Navbar bg="dark"  >
        
        <Container>      
            <img src={deltadefenseonefouthsize} alt="deltadefenseonefouthsize" />   
            <img src={usccafourthsize} alt="uscca" />   
        </Container>

      </Navbar>
     
      <Navbar bg="light" >
        <Container>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
           
            <Col>
            <p class="headertext"><em> Here to Help 24/7: </em> 800-674-9779 </p> 

            </Col>
   
        </Container>
      </Navbar>
      </BrowserView>
    </>
  );
}

export default BrandExample;