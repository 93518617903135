import React from 'react';
import Sticky from 'react-sticky-el';
import Page from './components/Page';
import Page1 from './components/Page1';

import Newheader from'./components/Newheader';
import './App.css';

function App() {
  return (
    <div className="App">
       
       <Sticky>
      <Newheader/>
      </Sticky>
      <Page1 />
      <Page />

    

    </div>
  );
};

export default App;
