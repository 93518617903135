import React from 'react';
import { Row, Col, Container} from 'react-bootstrap'
import './Page.css';
import uscca from './uscca.jpg';
import usccacommunity from './newusccacommunity.jpg';
import usccahalfsize from './usccahalfsize.jpg';
import deltadefenseonefouthsize from './deltadefenseonefouthsize.jpg';
import usccafourthsize from './usccafourthsize.jpg';
import deltadefenseline from './deltadefenseline.jpg';
export const Page = ()  => (
    
  
    <div>
    
    
 {/* Commenting code */}










           





<Container>
    <Row>
        <Col>
        <div class="v2" align="right"></div>
        </Col>
        <Col>
        <div class="v2" align="left"></div>
        </Col>
        <Col>
        <div class="container p-3 my-3 bg-light text-black responsive">
            <h1 align="left"> Online Community</h1>
            <p align="left">Join fun, gun friendly conversations with more than 80,0000 like-minded<br></br></p>
            <p align="left">Americans on our own ad-free social media platform</p>
            </div>  
        </Col>
    </Row>
</Container>

<Container>
    <Row>
        <Col>
        <div class="container p-3 my-3 bg-light text-black">
            <h1 align="left"> Online Community</h1>
            <p align="left">Join fun, gun friendly conversations with more than 80,0000 like-minded<br></br>Americans on our own ad-free social media platform</p>
            
            </div>
        </Col>
        <Col>
        
        </Col>
        <Col>
            
        </Col>
    </Row>
</Container>

<Container>
    <Row>
        <Col>
        
        </Col>
        <Col>
        
        </Col>
        <Col>
        <div class="container p-3 my-3 bg-light text-black">
            <h1> Online Community</h1>
            <ph>Join fun, gun friendly conversations with more than 80,0000 like-minded<br></br></ph>
            <p class="text-left">Americans on our own ad-free social media platform</p>
            </div>  
        </Col>
    </Row>
</Container>

<Container>
    <Row>
        <Col>
        <div class="container p-3 my-3 bg-light text-black">
            <h1> Online Community</h1>
            <ph>Join fun, gun friendly conversations with more than 80,0000 like-minded<br></br></ph>
            <p class="text-left">Americans on our own ad-free social media platform</p>
            </div>
        </Col>
        <Col>
            
        </Col>
    </Row>
</Container>






</div>


)

export default Page;